import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { X, Edit2 } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateCourse = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    description: '',
    course_type: 'annual',
    category_id: '',
    group_id: '',
    enquiries_email: '',
    whatsapp_number: '',
    terms_conditions: '',
    seats_availability: []
  });
  const [seatDate, setSeatDate] = useState('');
  const [seatCount, setSeatCount] = useState('');
  const [editingSeatIndex, setEditingSeatIndex] = useState(null);
  const [editDate, setEditDate] = useState('');
  const [editSeats, setEditSeats] = useState('');

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'align',
    'color', 'background',
    'link', 'image'
  ];

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://staging.rightships.com/courseCategory/get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          page: 1,
          limit: 100,
          is_parent: true
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setCategories(data.categories);
      }
    } catch (err) {
      setError('Failed to fetch categories');
    }
  };

  const fetchGroups = async (categoryId) => {
    if (!categoryId) return;
    try {
      const response = await fetch('https://staging.rightships.com/courseCategoryGroup/get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category_id: categoryId,
          page: 1,
          limit: 100
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setGroups(data.groups);
      }
    } catch (err) {
      setError('Failed to fetch groups');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://staging.rightships.com/course/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if (data.code === 200) {
        navigate('/courses');
      } else {
        setError(data.msg || 'Failed to create course');
      }
    } catch (err) {
      setError('Failed to create course');
    }
  };

  const handleAddSeat = () => {
    if (!seatDate || !seatCount) return;
    setFormData(prev => ({
      ...prev,
      seats_availability: [
        ...prev.seats_availability,
        { date: seatDate, seats: parseInt(seatCount) }
      ]
    }));
    setSeatDate('');
    setSeatCount('');
  };

  const handleRemoveSeat = (index) => {
    setFormData(prev => ({
      ...prev,
      seats_availability: prev.seats_availability.filter((_, i) => i !== index)
    }));
  };

  const handleEditSeat = (index) => {
    const seat = formData.seats_availability[index];
    setEditingSeatIndex(index);
    setEditDate(seat.date);
    setEditSeats(seat.seats.toString());
  };

  const handleUpdateSeat = () => {
    if (!editDate || !editSeats) return;
    
    setFormData(prev => ({
      ...prev,
      seats_availability: prev.seats_availability.map((seat, index) => 
        index === editingSeatIndex 
          ? { date: editDate, seats: parseInt(editSeats) }
          : seat
      )
    }));
    
    setEditingSeatIndex(null);
    setEditDate('');
    setEditSeats('');
  };

  const handleCancelEdit = () => {
    setEditingSeatIndex(null);
    setEditDate('');
    setEditSeats('');
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Create New Course</h1>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-1">Course Type</label>
            <select
              value={formData.course_type}
              onChange={(e) => setFormData({ ...formData, course_type: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
              required
            >
              <option value="annual">Annual</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Category</label>
            <select
              value={formData.category_id}
              onChange={(e) => {
                const categoryId = e.target.value;
                setFormData(prev => ({ ...prev, category_id: categoryId, group_id: '' }));
                fetchGroups(categoryId);
              }}
              className="w-full border rounded-md px-3 py-2"
              required
            >
              <option value="">Select Category</option>
              {categories.map(category => (
                <option key={category.category_id} value={category.category_id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {formData.category_id && (
          <div>
            <label className="block text-sm font-medium mb-1">Group (Optional)</label>
            <select
              value={formData.group_id}
              onChange={(e) => setFormData({ ...formData, group_id: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            >
              <option value="">Select Group</option>
              {groups.map(group => (
                <option key={group.group_id} value={group.group_id}>
                  {group.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-1">Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Subtitle (Optional)</label>
            <input
              type="text"
              value={formData.subtitle}
              onChange={(e) => setFormData({ ...formData, subtitle: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            />
          </div>
        </div>

        {formData.course_type === 'annual' && (
          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <div className="border rounded-md h-96">
              <ReactQuill
                theme="snow"
                value={formData.description}
                onChange={(value) => setFormData({ ...formData, description: value })}
                modules={modules}
                formats={formats}
                className="h-80"
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-1">Enquiries Email (Optional)</label>
            <input
              type="email"
              value={formData.enquiries_email}
              onChange={(e) => setFormData({ ...formData, enquiries_email: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">WhatsApp Number (Optional)</label>
            <input
              type="text"
              value={formData.whatsapp_number}
              onChange={(e) => setFormData({ ...formData, whatsapp_number: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            />
          </div>
        </div>

        {formData.course_type === 'monthly' && (
          <div>
            <label className="block text-sm font-medium mb-1">Seat Availability</label>
            <div className="space-y-4">
              <div className="flex gap-4 items-end">
                <div>
                  <label className="block text-sm font-medium mb-1">Date</label>
                  <input
                    type="date"
                    value={seatDate}
                    onChange={(e) => setSeatDate(e.target.value)}
                    className="border rounded-md px-3 py-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Seats</label>
                  <input
                    type="number"
                    value={seatCount}
                    onChange={(e) => setSeatCount(e.target.value)}
                    className="border rounded-md px-3 py-2"
                    min="1"
                  />
                </div>
                <button
                  type="button"
                  onClick={handleAddSeat}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Add
                </button>
              </div>

              <div className="space-y-2">
                {formData.seats_availability.map((seat, index) => (
                  <div key={index} className="flex items-center justify-between bg-gray-50 p-3 rounded">
                    {editingSeatIndex === index ? (
                      <div className="flex gap-4 items-center flex-1 mr-4">
                        <input
                          type="date"
                          value={editDate}
                          onChange={(e) => setEditDate(e.target.value)}
                          className="border rounded-md px-3 py-2"
                        />
                        <input
                          type="number"
                          value={editSeats}
                          onChange={(e) => setEditSeats(e.target.value)}
                          className="border rounded-md px-3 py-2"
                          min="1"
                        />
                        <div className="flex gap-2">
                          <button
                            type="button"
                            onClick={handleUpdateSeat}
                            className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelEdit}
                            className="px-3 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex gap-4">
                        <span>{new Date(seat.date).toLocaleDateString()}</span>
                        <span>{seat.seats} seats</span>
                      </div>
                    )}
                    {editingSeatIndex !== index && (
                      <div className="flex gap-2">
                        <button
                          type="button"
                          onClick={() => handleEditSeat(index)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <Edit2 className="w-4 h-4" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleRemoveSeat(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end gap-4 pt-6">
          <button
            type="button"
            onClick={() => navigate('/courses')}
            className="px-4 py-2 border rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Create Course
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCourse;
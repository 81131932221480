import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PromoCompanyForm = () => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    website_url: '',
    image_url: '',
    tags: [],
    display_order: 0,
    is_active: true
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.image_url) {
      setError('Company logo is required');
      return;
    }
    if (formData.tags.length === 0) {
      setError('Please select at least one tag');
      return;
    }

    try {
      setSaving(true);
      setError(null);
      
      const response = await fetch('https://staging.rightships.com/company/promo/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.msg || 'Failed to create company');
      }

      navigate('/admin/promo-companies');
    } catch (err) {
      setError(err.message);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setSaving(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      setError(null);
      
      const response = await fetch('https://staging.rightships.com/upload', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to upload image');
      }

      const data = await response.json();
      setFormData(prev => ({ ...prev, image_url: data.file_url }));
    } catch (err) {
      setError(err.message);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setUploading(false);
    }
  };

  const tagOptions = ['standard', 'top', 'list'];

  return (
    <div className="max-w-2xl mx-auto p-6 space-y-6">
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          {error}
        </div>
      )}

      <header className="flex items-center gap-4">
        <button 
          onClick={() => navigate('/admin/promo-companies')}
          className="p-2 hover:bg-gray-100 rounded-full"
          type="button"
        >
          Back
        </button>
        <h1 className="text-2xl font-bold">Create Promotional Company</h1>
      </header>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Logo <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center gap-4">
            {formData.image_url && (
              <img
                src={formData.image_url}
                alt="Preview"
                className="w-20 h-20 object-cover rounded-lg border"
              />
            )}
            <label className={`cursor-pointer flex items-center gap-2 px-4 py-2 border rounded-lg ${
              uploading ? 'bg-gray-100' : 'hover:bg-gray-50'
            }`}>
              {uploading ? 'Uploading...' : 'Upload Logo'}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
                disabled={uploading}
              />
            </label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            value={formData.name}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            className="w-full px-3 py-2 border rounded-lg"
            disabled={saving}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Website URL <span className="text-red-500">*</span>
          </label>
          <input
            type="url"
            required
            value={formData.website_url}
            onChange={(e) => setFormData(prev => ({ ...prev, website_url: e.target.value }))}
            className="w-full px-3 py-2 border rounded-lg"
            placeholder="https://example.com"
            disabled={saving}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Tags <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-wrap gap-2">
            {tagOptions.map(tag => (
              <button
                key={tag}
                type="button"
                onClick={() => setFormData(prev => ({
                  ...prev,
                  tags: prev.tags.includes(tag)
                    ? prev.tags.filter(t => t !== tag)
                    : [...prev.tags, tag]
                }))}
                className={`px-4 py-2 rounded-lg ${
                  formData.tags.includes(tag)
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
                disabled={saving}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Display Order <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            required
            min="0"
            value={formData.display_order}
            onChange={(e) => setFormData(prev => ({ 
              ...prev, 
              display_order: parseInt(e.target.value) || 0
            }))}
            className="w-full px-3 py-2 border rounded-lg"
            disabled={saving}
          />
        </div>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="is_active"
            checked={formData.is_active}
            onChange={(e) => setFormData(prev => ({ 
              ...prev, 
              is_active: e.target.checked 
            }))}
            className="w-4 h-4"
            disabled={saving}
          />
          <label htmlFor="is_active" className="text-sm text-gray-700">
            Active
          </label>
        </div>

        <div className="flex justify-end gap-3 pt-6">
          <button
            type="button"
            onClick={() => navigate('/admin/promo-companies')}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
            disabled={saving}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={saving || uploading}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            {saving ? 'Creating...' : 'Create Company'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PromoCompanyForm;
// // src/pages/Categories.jsx
// import { useState } from 'react';
// import Button2 from '../components/Button';
// import Modal2 from '../components/Modal';
// import CategoryForm from './CategoryForm';
// import CategoryList from './CategoryList';



// export default function Categories() {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [refreshList, setRefreshList] = useState(false);

//   const handleAddNew = () => {
//     setSelectedCategory(null);
//     setShowModal(true);
//   };

//   const handleEdit = (category) => {
//     setSelectedCategory(category);
//     setShowModal(true);
//   };

//   const handleFormSuccess = () => {
//     setShowModal(false);
//     setRefreshList(prev => !prev);
//   };

//   return (
//     <div className="space-y-6">
//       <div className="flex justify-between items-center">
//         <h1 className="text-2xl font-bold">Categories</h1>
//         <Button2 onClick={handleAddNew}>
//           <span className="flex items-center">
//             <i className="material-icons-outlined mr-1">add</i>
//             Add Category
//           </span>
//         </Button2>
//       </div>

//       <CategoryList
//         onEdit={handleEdit}
//         refresh={refreshList}
//       />

//       <Modal2
//         isOpen={showModal}
//         onClose={() => setShowModal(false)}
//         title={selectedCategory ? 'Edit Category' : 'Add New Category'}
//       >
//         <CategoryForm
//           category={selectedCategory}
//           onSuccess={handleFormSuccess}
//           onCancel={() => setShowModal(false)}
//         />
//       </Modal2>
//     </div>
//   );
// }


import React, { useState, useEffect } from 'react';
import { PlusCircle, Edit2, Trash2, ChevronRight, X } from 'lucide-react';

const Notification = ({ message, type, onClose }) => (
  <div className={`p-4 rounded-lg mt-4 flex justify-between items-center ${
    type === 'error' ? 'bg-red-50 text-red-700 border border-red-200' :
    'bg-green-50 text-green-700 border border-green-200'
  }`}>
    <span>{message}</span>
    <button onClick={onClose} className="text-current">
      <X className="w-4 h-4" />
    </button>
  </div>
);

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{title}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const CategoryForm = ({ onSubmit, initialData, onClose }) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    description: initialData?.description || ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Name
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="w-full border rounded-md px-3 py-2"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          className="w-full border rounded-md px-3 py-2"
          rows="3"
          required
        />
      </div>
      <div className="flex justify-end gap-2">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          {initialData ? 'Update' : 'Create'}
        </button>
      </div>
    </form>
  );
};

const GroupForm = ({ onSubmit, initialData, onClose }) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    description: initialData?.description || ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Name
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="w-full border rounded-md px-3 py-2"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          className="w-full border rounded-md px-3 py-2"
          rows="3"
          required
        />
      </div>
      <div className="flex justify-end gap-2">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          {initialData ? 'Update' : 'Create'}
        </button>
      </div>
    </form>
  );
};

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingGroup, setEditingGroup] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const baseUrl = 'https://staging.rightships.com';

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${baseUrl}/courseCategory/get`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ page: 1, limit: 100, is_parent: true })
      });
      const data = await response.json();
      if (data.code === 200) {
        setCategories(data.categories);
      }
    } catch (err) {
      setError('Failed to fetch categories');
    }
  };

  const fetchGroups = async (categoryId) => {
    try {
      const response = await fetch(`${baseUrl}/courseCategoryGroup/get`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ page: 1, limit: 100, category_id: categoryId })
      });
      const data = await response.json();
      if (data.code === 200) {
        setGroups(data.groups);
      }
    } catch (err) {
      setError('Failed to fetch groups');
    }
  };

  const createCategory = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}/courseCategory/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...formData,
          is_parent: true,
          is_active: true
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setSuccess('Category created successfully');
        fetchCategories();
        setIsAddingCategory(false);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError('Failed to create category');
    }
  };

  const editCategory = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}/courseCategory/edit`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category_id: editingCategory.category_id,
          ...formData
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setSuccess('Category updated successfully');
        fetchCategories();
        setEditingCategory(null);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError('Failed to update category');
    }
  };

  const deleteCategory = async (categoryId) => {
    if (!window.confirm('Are you sure you want to delete this category?')) return;
    
    try {
      const response = await fetch(`${baseUrl}/courseCategory/delete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ category_id: categoryId })
      });
      const data = await response.json();
      if (data.code === 200) {
        setSuccess('Category deleted successfully');
        fetchCategories();
        if (selectedCategory === categoryId) {
          setSelectedCategory(null);
          setGroups([]);
        }
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError('Failed to delete category');
    }
  };

  const createGroup = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}/courseCategoryGroup/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...formData,
          category_id: selectedCategory
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setSuccess('Group created successfully');
        fetchGroups(selectedCategory);
        setIsAddingGroup(false);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError('Failed to create group');
    }
  };

  const editGroup = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}/courseCategoryGroup/edit`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          group_id: editingGroup.group_id,
          ...formData
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setSuccess('Group updated successfully');
        fetchGroups(selectedCategory);
        setEditingGroup(null);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError('Failed to update group');
    }
  };

  const deleteGroup = async (groupId) => {
    if (!window.confirm('Are you sure you want to delete this group?')) return;
    
    try {
      const response = await fetch(`${baseUrl}/courseCategoryGroup/delete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ group_id: groupId })
      });
      const data = await response.json();
      if (data.code === 200) {
        setSuccess('Group deleted successfully');
        fetchGroups(selectedCategory);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError('Failed to delete group');
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchGroups(selectedCategory);
    }
  }, [selectedCategory]);

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Categories Section */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Categories</h2>
            <button
              onClick={() => setIsAddingCategory(true)}
              className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              <PlusCircle className="w-4 h-4" />
              Add Category
            </button>
          </div>

          <div className="space-y-4">
            {categories.map((category) => (
              <div
                key={category.category_id}
                className={`p-4 border rounded-lg cursor-pointer transition-colors ${
                  selectedCategory === category.category_id
                    ? 'border-blue-500 bg-blue-50'
                    : 'hover:bg-gray-50'
                }`}
                onClick={() => setSelectedCategory(category.category_id)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="font-semibold">{category.name}</h3>
                    <p className="text-sm text-gray-600">{category.description}</p>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingCategory(category);
                      }}
                      className="p-2 text-gray-600 hover:text-blue-500"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteCategory(category.category_id);
                      }}
                      className="p-2 text-gray-600 hover:text-red-500"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Groups Section */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Groups</h2>
            {selectedCategory && (
              <button
                onClick={() => setIsAddingGroup(true)}
                className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                <PlusCircle className="w-4 h-4" />
                Add Group
              </button>
            )}
          </div>

          {selectedCategory ? (
            <div className="space-y-4">
              {groups.map((group) => (
                <div
                  key={group.group_id}
                  className="p-4 border rounded-lg hover:bg-gray-50"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-semibold">{group.name}</h3>
                      <p className="text-sm text-gray-600">{group.description}</p>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => deleteGroup(group.group_id)}
                        className="p-2 text-gray-600 hover:text-red-500"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
              <ChevronRight className="w-12 h-12 mb-4" />
              <p>Select a category to view its groups</p>
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      <Modal
        isOpen={isAddingCategory}
        onClose={() => setIsAddingCategory(false)}
        title="Add Category"
      >
        <CategoryForm
          onSubmit={createCategory}
          onClose={() => setIsAddingCategory(false)}
        />
      </Modal>

      <Modal
        isOpen={editingCategory !== null}
        onClose={() => setEditingCategory(null)}
        title="Edit Category"
      >
        <CategoryForm
          initialData={editingCategory}
          onSubmit={editCategory}
          onClose={() => setEditingCategory(null)}
        />
      </Modal>

      <Modal
        isOpen={isAddingGroup}
        onClose={() => setIsAddingGroup(false)}
        title="Add Group"
      >
        <GroupForm
          onSubmit={createGroup}
          onClose={() => setIsAddingGroup(false)}
        />
      </Modal>

      <Modal
        isOpen={editingGroup !== null}
        onClose={() => setEditingGroup(null)}
        title="Edit Group"
      >
        <GroupForm
          initialData={editingGroup}
          onSubmit={editGroup}
          onClose={() => setEditingGroup(null)}
        />
      </Modal>

      {/* Notifications */}
      {error && (
        <Notification 
          message={error} 
          type="error" 
          onClose={() => setError('')}
        />
      )}
      {success && (
        <Notification 
          message={success} 
          type="success" 
          onClose={() => setSuccess('')}
        />
      )}
    </div>
  );
};

export default Categories;
                      

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EditPromoCompany = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    website_url: '',
    image_url: '',
    tags: [],
    display_order: 0,
    is_active: true
  });

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await fetch('https://staging.rightships.com/company/promo/get', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ company_id: id }),
        });
        
        if (!response.ok) throw new Error('Failed to fetch company');
        const data = await response.json();
        
        if (data.companies?.[0]) {
          const company = data.companies[0];
          setFormData({
            name: company.name,
            website_url: company.website_url,
            image_url: company.image_url,
            tags: company.tags || [],
            display_order: company.display_order,
            is_active: company.is_active
          });
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCompany();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch('https://staging.rightships.com/company/promo/edit', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, company_id: id }),
      });

      if (!response.ok) throw new Error('Failed to update company');
      navigate('/admin/promo-companies');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      const response = await fetch('https://staging.rightships.com/upload', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) throw new Error('Failed to upload image');
      const data = await response.json();
      setFormData(prev => ({ ...prev, image_url: data.file_url }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const tagOptions = ['standard', 'top', 'list'];

  if (loading) return <div className="text-center p-6">Loading...</div>;

  return (
    <div className="max-w-2xl mx-auto p-6">
      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-lg">
          {error}
        </div>
      )}

      <header className="flex items-center gap-4 mb-6">
        <button 
          onClick={() => navigate('/admin/promo-companies')}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          Back
        </button>
        <h1 className="text-2xl font-bold">Edit Company</h1>
      </header>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Logo *
          </label>
          <div className="flex items-center gap-4">
            {formData.image_url && (
              <img
                src={formData.image_url}
                alt="Company logo"
                className="w-20 h-20 object-cover rounded-lg"
              />
            )}
            <label className="cursor-pointer px-4 py-2 border rounded-lg hover:bg-gray-50">
              Change Logo
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
            </label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Name *
          </label>
          <input
            type="text"
            required
            value={formData.name}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Website URL *
          </label>
          <input
            type="url"
            required
            value={formData.website_url}
            onChange={(e) => setFormData(prev => ({ ...prev, website_url: e.target.value }))}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Tags *
          </label>
          <div className="flex gap-2 flex-wrap">
            {tagOptions.map(tag => (
              <button
                key={tag}
                type="button"
                onClick={() => setFormData(prev => ({
                  ...prev,
                  tags: prev.tags.includes(tag)
                    ? prev.tags.filter(t => t !== tag)
                    : [...prev.tags, tag]
                }))}
                className={`px-4 py-2 rounded-lg ${
                  formData.tags.includes(tag)
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Display Order *
          </label>
          <input
            type="number"
            required
            min="0"
            value={formData.display_order}
            onChange={(e) => setFormData(prev => ({ 
              ...prev, 
              display_order: parseInt(e.target.value) || 0
            }))}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="is_active"
            checked={formData.is_active}
            onChange={(e) => setFormData(prev => ({ 
              ...prev, 
              is_active: e.target.checked 
            }))}
            className="w-4 h-4"
          />
          <label htmlFor="is_active" className="text-sm text-gray-700">
            Active
          </label>
        </div>

        <div className="flex justify-end gap-3 pt-6">
          <button
            type="button"
            onClick={() => navigate('/admin/promo-companies')}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPromoCompany;